(function($){
	window.app = {
		selectors: {
			listTable: '.main-table',
			footable: '#footable',
			footablePage: '.footable-page-number',
			footableNoResults: '.no-results'
		},

		config: {

		},

		init: function(){
			var app = this;

			app.fastclick();
			app.offcanvas();
			app.footable();
			app.selectric();
			app.confirmActions();
		},

		confirmActions: function(){
			$('.delete-item, .mark-collected-item, .refresh-item').click(function(){
				var confirmed = confirm($(this).data('confirm'));
				
				if(!confirmed){
					return false;
				}
			});
		},

		offcanvas: function(){
			$( window ).resize(function() {
				UIkit.offcanvas.hide([force = false]);
			});
		},

		fastclick: function(){
			FastClick.attach(document.body);
		},

		footable: function(){
			var app = this;
			var prev = $('ul.pagination').find('[data-page="prev"]');
			var next = $('ul.pagination').find('[data-page="next"]');

			var footable = $(app.selectors.footable)
			.on('footable_initializing', function(e) {
				$(app.selectors.listTable).css('visibility', 'visible');
			})
			.footable({
				debug: true,
				log: function(message, type) {
                    console.log(message);
                },
				breakpoints: { //UIKIT breakpoints
			        phone: 480,
			        tablet: 700,
			        desktop: 900
			    }
			})
			.on('footable_paging', function(e){
				var pageNumber = e.page + 1;
				$(app.selectors.footablePage).html(pageNumber);
				$(document.body).scrollTop(0);
				$('.footable-page').blur();
			})
			.on('footable_filtered', function(e){
				var footable = $(this);
				var results = footable.find('tr:not(.footable-filtered)').length - 1;

				if(results == 0) {
					$(app.selectors.listTable).addClass('no-results-found');
					$(app.selectors.footableNoResults).css('display', 'block');
					$(app.selectors.footable).css('display', 'none');
				} else {
					$(app.selectors.listTable).removeClass('no-results-found');
					$(app.selectors.footableNoResults).css('display', 'none');
					$(app.selectors.footable).css('display', 'table');
				}

				if(results <= footable.data('page-size')){
					$(app.selectors.listTable).addClass('no-pagination');
				} else {
					$(app.selectors.listTable).removeClass('no-pagination');
				}
			})
			.on('click', '.row-delete', function(e) {
			    e.preventDefault();
			    //get the footable object
			    var footable = $('table').data('footable');

			    //get the row we are wanting to delete
			    var row = $(this).parents('tr:first');

			    //delete the row
			    footable.removeRow(row);
			});

			$(document).on('keydown', null, function(e){
				if(e.keyCode == 37){
					$('ul.pagination').find('[data-page="prev"]').trigger('click');
				} else if(e.keyCode == 39){
					$('ul.pagination').find('[data-page="next"]').trigger('click');
				}
			});
		},

		selectric: function(){
			$('select[name="category"]').on('selectric-before-init selectric-change', function(e){
				switch(this.value) {
				    case 'hrany':
				    	$('._plosny-material').addClass('uk-hidden');
				    	$('._kovani').addClass('uk-hidden');
				    	$('._hrany').removeClass('uk-hidden');

				    	$('input[name="width"]').addClass('uk-hidden');
				    	$('input[name="height"]').addClass('uk-hidden');
				    	$('input[name="thickness"]').removeClass('uk-hidden');
				    	$('label.dimensions').addClass('uk-hidden');
				        $('input[name="length"]').removeClass('uk-hidden');
				        $('#dimensions').removeClass('uk-hidden');
				        break;
				    case 'kovani':
				    	$('._plosny-material').addClass('uk-hidden');
				    	$('._hrany').addClass('uk-hidden');
				    	$('._kovani').removeClass('uk-hidden');

				    	$('#dimensions').addClass('uk-hidden');
				        break;
				    default:
				    	$('._hrany').addClass('uk-hidden');
				    	$('._kovani').addClass('uk-hidden');
				    	$('._plosny-material').removeClass('uk-hidden');

				        $('input[name="width"]').removeClass('uk-hidden');
				    	$('input[name="height"]').removeClass('uk-hidden');
				    	$('input[name="thickness"]').removeClass('uk-hidden');
				    	$('label.dimensions').removeClass('uk-hidden');
				        $('input[name="length"]').addClass('uk-hidden');
				        $('#dimensions').removeClass('uk-hidden');
				}
			});

			$('select[name="brand"], select[name="brand-hrany"], select[name="brand-kovani"]').on('selectric-before-init selectric-change', function(e){
				switch(this.value) {
				    case 'ostatní':
				    	$('.brand-other').removeClass('uk-hidden');
				        break;
				    default:
				    	$('.brand-other').addClass('uk-hidden');
				}
			});

			$('select').selectric({
				customClass: {
				    prefix: 'selectric',
				    camelCase: false,
				    overwrite: true,
				},
				optionsItemBuilder: '<span class="{value}">{text}</span>',
				maxHeight: 400,
				labelBuilder: '<span class="{value}">{text}</span>'
			});
		}
	}			

	$(document).on('ready', function($){
		app.init();
	});

})(jQuery)